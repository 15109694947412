import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

export default makeStyles((theme) => ({
  goToAll: {
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: '#3090E8 !important',
    },
  },
  blur: {
    filter: 'blur(20px)',
  },
  root: {
    display: 'grid',
    transition: 'all 0.3s ease-in-out',
  },
  pageCaption: {
    color: '#353E47',
    width: '100%',
    margin: 0,
    display: 'flex',
    fontSize: 16,
    fontStyle: 'normal',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: 400,
    justifyContent: 'center',
  },
  new_editor_list_menu: {
    display: 'grid',
    gridTemplateColumns: '207px 1fr 50px',
    width: '100%',
    height: '100%',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& h2': {
      width: '100%',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#353E47',
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media (max-width: 500px)': {
      gridTemplateColumns: '50px 1fr 50px',
    },
  },
  contentWrapper: {
    boxShadow: 'none',
    backgroundColor: '#f3f3f3',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '61px 1fr',
  },
  toolbar: {
    minHeight: 60,
    display: 'flex',
    position: 'relative',
    background: '#FCFEFF',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(87, 87, 87, 0.3)',
    padding: 0,
  },
  publicToolbar: {
    minHeight: 60,
    display: 'grid',
    gridTemplateColumns: '1fr 80px',
    position: 'relative',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 58px)',
    overflow: 'auto',
  },
  logoContainer: {
    cursor: 'pointer',
    textDecoration: 'none !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  logoContainerRollUp: {
    width: '57px',
  },
  logoContainerRollDown: {
    width: '100%',
  },
  logoTitle: {
    color: '#03336E',
    fontWeight: 'bold',
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
  meta: {
    padding: theme.spacing(2),
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  title: {
    flexGrow: 1,
  },
  signOut: {
    color: '#fff',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
    fontSize: 16,
  },
  name: {
    display: 'block',
    top: 0,
    left: 11,
    position: 'relative',
    color: '#03336E',
    textTransform: 'initial',
    width: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userBtn: {
    display: 'block',
    paddingLeft: 2,
    alignSelf: 'center',
    padding: 0,
    margin: '10px auto',
    '& span.MuiButton-label': {
      transition: 'all 0.3s ease-in-out',
      display: 'grid',
      height: 35,
    },
  },
  userBtnRollDown: {
    width: '35px !important',
    '& span.MuiButton-label': {
      gridTemplateColumns: '30px',
      '& > p': {
        display: 'none',
      },
    },
  },
  userBtnRollUp: {
    width: '200px !important',
    '& span.MuiButton-label': {
      gridTemplateColumns: '30px 1fr',
    },
  },
  feedbackLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 192,
      height: 32,
      viewBox: '0 0 192 32',
    },
    '@media (max-width: 500px)': {
      width: 32,
      overflow: 'hidden',
      paddingRight: 10,
      '& svg': {
        width: 32,
        viewBox: '0 0 32 32',
      },
      '& .new_feedback_caption': {
        display: 'none',
      },
    },
  },
  head: {
    width: '100%',
    height: 50,
    overflow: 'hidden',
    display: 'flex',
    columnGap: 20,
    marginLeft: 20,
  },
  back: {
    width: 123,
    height: 46,
    border: '1px solid #03336E',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#03336E',
    textTransform: 'capitalize',
    justifyContent: 'space-around',
  },
  h2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    display: 'flex',
    padding: 0,
    margin: 0,
    alignItems: 'center',
    columnGap: 8,
    '& span.main': {
      color: '#03336E',
      '& a': {
        textDecoration: 'none',
        color: '#03336E',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& span.info': {
      color: '#6F8194',
    },
  },

  toggleBtn: {
    width: 200,
    height: 46,
    border: '1px solid #03336E',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#03336E',
    textTransform: 'capitalize',
    justifyContent: 'space-between',
  },

  sitemapOption: {
    display: 'flex',
    justifyContent: 'space-around',
    columnGap: 10,
    marginRight: 20,
  },
  sitemapOptionBTN: {
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontWeight: 500,
    fontSize: 14,
    color: '#ffffff',
    display: 'flex',
    width: 70,
    height: 32,
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
  },
  sitemapOptionRemove: {
    background: '#EE6475',
    border: '1px solid #EE6475',
    width: 28,
  },
  btnGroup: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    position: 'relative',
    '@media (max-width: 700px)': {
      gridTemplateColumns: '90px 1fr 1fr',
      '& .newbtn_caption': {
        display: 'none',
      },
    },
    '& div.item': {
      display: 'flex',
      alignItems: 'center',
    },
    '& div.item.fullWidth': {
      width: '100%',
    },
    '& div.item:nth-child(2)': {
      justifySelf: 'center',
      position: 'relative',
      '& svg': {
        position: 'absolute',
        right: 0,
      },
    },
    '& div.item:nth-child(3)': {
      justifySelf: 'end',
    },
  },
  btnGroupProfile: {
    '@media (max-width: 900px)': {
      gridTemplateColumns: '110px 1fr 60px',
      '& .newbtn_caption': {
        display: 'none',
      },
      '& .feedback_link > svg': {
        width: 32,
        viewBox: '0 0 32 32',
        paddingRight: 10,
      },
      '& .new_feedback_caption': {
        display: 'none',
      },
    },
  },
  btnGroupTemplate: {
    '@media (max-width: 700px)': {
      gridTemplateColumns: '110px 1fr 120px',
      '& .newbtn_caption': {
        display: 'none',
      },
      '& .feedback_link > svg': {
        width: 32,
        viewBox: '0 0 32 32',
        paddingRight: 10,
      },
      '& .new_feedback_caption': {
        display: 'none',
      },
    },
  },
  redBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 152,
    height: '100%',
    background: '#FD1F6D',
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    '& svg': {
      display: 'none',
    },
    '@media (max-width: 900px)': {
      '& svg': {
        display: 'block',
      },
      width: 60,
      '& span': {
        display: 'none',
      },
    },
    '&:hover': {
      background: 'rgba(253, 31, 109, 0.5)',
      cursor: 'pointer',
    },
  },
}));
