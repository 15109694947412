import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import { UserAvatar } from './UserAvatar';
import cx from 'clsx';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { Logout } from './Logout';

export const User = () => {
  const classes = useStyles();
  const profile = useTypeSelector((s) => s.profile.profile);

  return (
    <div>
      {profile && (
        <div className={cx(classes.wrapper, classes.flex)}>
          <div className={classes.avaBlockWraper}>
            <UserAvatar />
            <Grid className={classes.align}>
              <Typography variant="h4" className={classes.avaName}>
                {profile.name ? profile.name : 'Имя'}
              </Typography>
              <Typography variant="h4" className={classes.avaName}>
                {profile.surname ? profile.name : 'Фамилия'}
              </Typography>
            </Grid>
          </div>

          <div className={classes.out}>
            <span className={classes.description}>{profile.email}</span>
            <Logout />
          </div>
        </div>
      )}
    </div>
  );
};
