import { makeStyles } from '@material-ui/core';

export const ProfileActiveColor = '#F2994A';
const HistoryListColumns = '3fr 2fr';
export const useProfileStyles = makeStyles((theme) => {
  const padding = 24;
  const gap = 24;

  return {
    select_tariffs_wrapper: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    select_tariffs_wrapper_mobile: {
      display: 'grid',
      justifyContent: 'center',
      rowGap: 24,
    },
    select_tariffs_wrapper__sidebar: {
      width: 240,
      '& .header': {
        height: 230,
        position: 'relative',
        display: 'block',
      },
      '& h3': {
        fontWeight: 700,
        fontSize: 20,
        lineheight: '23px',
        color: '#333333',
        opacity: 0.5,
        position: 'absolute',
        bottom: 0,
      },
    },
    select_tariffs_wrapper__item: {
      width: 290,
      height: '100%',
      minHeight: 480,
      position: 'relative',
      '&:hover': {
        '&::before': {
          border: '2px solid #FD1F6D',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 'calc(100% - 40px)',
        height: 'calc(100% + 20px)',
        border: '2px solid rgba(241, 248, 254, 1)',
        borderRadius: 15,
        left: 38,
      },
      '& .header': {
        padding: '0 0 0 50px',
        height: 250,
        '& .desc': {
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '14px',
          textAlign: 'center',
        },
        '& h1': {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 20,
          textAlign: 'center',
        },
        '& h1.price': {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 20,
          lineHeight: '24px',
          textAlign: 'center',
        },
        '& p': {
          textAlign: 'center',
        },
      },
    },
    select_tariffs_wrapper__item_mobile: {
      width: 290,
      height: 210,
      minHeight: 'auto',
      position: 'relative',
      marginTop: 20,
      border: '2px solid rgba(241, 248, 254, 1)',
      borderRadius: 15,
      padding: 20,
      '&:hover': {
        '&::before': {
          border: '2px solid #FD1F6D',
        },
      },

      '& .header': {
        height: 250,
        '& .desc': {
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '14px',
          textAlign: 'center',
        },
        '& h1': {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 20,
          textAlign: 'center',
        },
        '& h1.price': {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 20,
          lineHeight: '24px',
          textAlign: 'center',
        },
        '& p': {
          textAlign: 'center',
        },
      },
    },
    sale: {
      '&:hover': {
        '&::before': {
          border: '2px solid #FD1F6D',
          borderTop: '40px solid #FD1F6D',
          opacity: 0.7,
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 'calc(100% - 40px)',
        height: 'calc(100% + 20px)',
        border: '2px solid #FD1F6D',
        borderTop: '40px solid #FD1F6D',
        borderRadius: 15,
        left: 38,
        color: 'white',
        top: '-40px',
      },
      '&::after': {
        position: 'absolute',
        color: 'white',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: -40,
        left: 23,
        height: 40,
        fontSize: 16,
        lineHeight: '19px',
      },
    },
    descount_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        margin: '0 0 20px 0',
      },
    },
    descount: {
      display: 'inline-flex',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: 4,
        backgroundColor: '#FD1F6D',
        top: 6,
        left: 0,
        transform: 'rotateZ(354deg)',
        opacity: 0.8,
      },
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        height: 48,
        padding: '0 0 0 53px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .center': {
        justifyContent: 'center',
        textAlign: 'center',
      },
      '& li:nth-child(2n)': {
        backgroundColor: 'rgba(241, 248, 254, 0.5)',
      },
    },
    promo: {
      position: 'relative',
      margin: '16px 0 16px 0 ',
      display: 'flex',
      columnGap: 8,
      rowGap: gap,
    },
    promoCheck: {
      margin: '10px 0 0 -11px',
    },
    promosvg: {
      position: 'absolute',
      top: 1,
      right: 1,
      userSelect: 'none',
      pointerEvents: 'none',
      '& .promo_bg': {
        fill: '#cccccc',
      },
      '& .promo_checks': {
        fill: '#F6FBFF',
      },
    },
    promosvgActive: {
      cursor: 'pointer',
      pointerEvents: 'all',
      zIndex: 9999,
      '& .promo_bg': {
        fill: '#F1F8FE',
      },
      '& .promo_checks': {
        fill: '#000000',
      },
    },
    promosvgSuccess: {
      cursor: 'pointer',
      pointerEvents: 'all',
      '& .promo_bg': {
        fill: '#3090E8',
      },
    },
    settingsWrapper: {
      width: `calc(100% - ${padding + gap}px)`,
      height: `calc(100vh - 100px)`,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      padding,
      rowGap: gap,
      columnGap: gap,
      '@media (max-width: 990px)': {
        gridTemplateColumns: '1fr',
        height: `fit-content`,
      },
    },
    card: {
      borderRadius: 16,
      background: '#FFF',
    },
    cardHeader: {
      padding: `${padding}px ${padding}px ${0}px ${padding}px`,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px' /* 120% */,
      letterSpacing: '0.6px',
      '@media (max-width: 498px)': {
          padding: '16px 16px 0',
        },
    },
    cardTariffs: {
      border: `1px solid ${ProfileActiveColor}`,
    },
    textActive: {
      color: ProfileActiveColor,
    },
    orangeBtn: {
      display: 'flex',
      width: '100%',
      minHeight: 40,
      padding: '8px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      borderRadius: 5,
      background: ProfileActiveColor,
      border: `1px solid ${ProfileActiveColor}`,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      transition: 'all 0.3s linear',
      color: 'white',
      '&:hover': {
        border: `1px solid ${ProfileActiveColor} !important`,
        background: `rgba(${ProfileActiveColor},0.3 ) !important`,
        color: ProfileActiveColor,
      },
    },
    orangeBtnOutline: {
      display: 'flex',
      width: '100%',
      minWidth: 100,
      height: 40,
      padding: '0px !important',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      borderRadius: 5,
      border: `1px solid ${ProfileActiveColor}`,
      background: 'transparent',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      color: ProfileActiveColor,
      transition: 'all 0.3s linear',
      '&:hover': {
        background: ProfileActiveColor,
        color: 'white',
      },
    },
    disabledOrangeBtnOutline: {
      pointerEvents: 'none',
      userSelect: 'none',
      opacity: 0.5,
      filter: 'gray(.5)',
    },
    wrapperOne: {
      border: '2px solid',
      boxSizing: 'border-box',
      borderRadius: 10,
      width: 243,
      height: 306,
      padding: 20,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '40px 1fr 40px 50px',
    },
    wrapperAll: {
      display: 'flex',
    },
    default: {
      borderColor: '#DCDCDC',
      backgroundColor: '#FFFFFF',
      '& h2': {
        backgroundColor: '#F3F3F3',
      },
    },
    success: {
      borderColor: '#2CBC63',
      backgroundColor: '#F2FCF6',
      '& h2': {
        backgroundColor: '#2CBC63',
      },
    },
    header: {
      height: 40,
      borderRadius: 5,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      padding: '6px 10px',
      margin: 0,
      display: 'inline-block',
    },
    tarifParams: {
      display: 'grid',
      gridTemplateRows: '40px 40px',
    },
    tarifParamItem: {
      display: 'grid',
      gridTemplateColumns: '30px 1fr',
      alignItems: 'center',
    },
    price: {
      display: 'block',
      fontWeight: 500,
      fontSize: 24,
      zIndex: 9999,
    },
    myTarrifBlock: {
      width: 190,
      height: 47,
      boxSizing: 'border-box',
      borderRadius: 8,
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    myTarrifActive: {
      background: '#F2FCF6',
      border: '2px solid #2CBC63',
      color: '#2CBC63',
    },
    myTarrifDisactive: {
      background: '#FFEDF1',
      border: '2px solid #FD1F6D',
      color: '#FD1F6D',
    },
    myTarrifWrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      columnGap: 20,
    },
    myTarrifCaption: {
      fontSize: 16,
      color: '#6F8194',
      margin: '0 10px',
    },
    myTarrifDate: {
      fontSize: 18,
      color: '#333333',
    },
    paymentListTable: {
      width: '100%',
      display: 'grid',
      border: '1px solid #A1AEBB',
      borderRadius: 10,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    paymentListTableRowHeader: {
      display: 'inline-grid',
      gridTemplateColumns: HistoryListColumns,
      backgroundColor: '#F6FBFF',

      alignItems: 'center',
      borderLeftRadius: 10,
      borderRightRadius: 10,
      '& span': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '19px',
        color: '#353E47',
        fontSize: 16,
        padding: 8,
        '& svg': {
          width: 30,
          height: 30,
        },
      },
      '& span:not(:first-child)': {
        borderLeft: '1px solid #A1AEBB',
      },
    },
    paymentListTableRow: {
      display: 'grid',
      gridTemplateColumns: HistoryListColumns,
      fontSize: 16,
      height: 35,
      borderTop: '1px solid #A1AEBB',
      alignItems: 'center',
      '& span': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '19px',
        color: '#353E47',
        padding: 8,
      },
      '& span:not(:first-child)': {
        borderLeft: '1px solid #A1AEBB',
      },
    },
    rightSide: {
      display: 'block',
      '& div.card': {
        marginTop: gap,
      },
    },
    orangeCheckbox: {
      '& span.MuiFormControlLabel-label': {
        userSelect: 'none',
      },
      '& span.MuiCheckbox-root > span.MuiIconButton-label > svg > path': {
        fill: ProfileActiveColor,
      },
    },
    hr: {
      width: '100%',
      marginBlock: 24,
      '@media (max-width: 498px)': {
          marginBlock: '16px',
        },
    },
    label: {
      fontFamily: 'Roboto',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.54px',
      display: 'flex',
      justifyContent: 'center',
    },
    listActives: {
      listStyle: 'none',
      padding: 16,
      display: 'grid',
      rowGap: gap,
      borderRadius: 12,
      background: 'rgba(242, 153, 74, 0.05)',
      '& li': {
        display: 'grid',
        gridTemplateColumns: '40px 1fr',
        alignItems: 'center',
        '& span': {
          color: ProfileActiveColor,
        },
      },
    },
    blueBtn: {
      width: '100%',
      display: 'flex',
      minHeight: 40,
      padding: '8px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      alignSelf: 'stretch',
      borderRadius: 5,
      border: '1px solid var(--Main-Blue, #3090E8)',
      background: 'var(--wite-fc, #FFF)',
      color: '#3090E8',
      fontWeight: 400,
    },
    textField: {
      display: 'block',
      marginBottom: theme.spacing(1),
      width: 240,
    },
    button: {
      marginTop: theme.spacing(3),
      width: '100%',
    },
    scrollable: {
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9,
      },
      '&::-webkit-scrollbar-track': {
        width: 4,
        background: 'transparent',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#353E47',
        borderRadius: 0,
        cursor: 'pointer',
        borderRight: '4px solid white',
        '&:hover': {
          backgroundColor: '#3090E8',
          cursor: 'pointer !important',
        },
      },
      '@media (max-width:990px)': {
        overflowY: 'visible',
      },
    },
  };
});
